.identity {
  flex-direction: row;
  align-items: center;
  gap: 50px;
  max-width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}
/*# sourceMappingURL=index.93cb1055.css.map */
